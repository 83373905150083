import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH, API_URL } from 'config';
import MainLogo from '../../../assets/images/ensite-builder-logo.png';
import { useSelector } from 'react-redux';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const { logo } = useSelector((state) => state.companyLogo);
    return (
        <Link component={RouterLink} to={DASHBOARD_PATH}>
            <img
                src={logo ? `${API_URL}/${logo}` : MainLogo}
                alt="logo"
                width="130px"
                height="100%"
                style={{ maxHeight: 70, objectFit: 'cover' }}
            />
        </Link>
    );
};

export default LogoSection;
