// firebaseConfig.js

import { fetchFirebaseCredentialsData, FIREBASE_CONFIG } from 'config';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

let app;
let messaging;

let isInitialized = false;

const initializeFirebase = async () => {
    await fetchFirebaseCredentialsData();
    try {
        // Firebase configuration object
        const firebaseConfig = {
            apiKey: FIREBASE_CONFIG.FIREBASE_API_KEY,
            authDomain: FIREBASE_CONFIG.FIREBASE_AUTH_DOMAIN,
            projectId: FIREBASE_CONFIG.FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_CONFIG.FIREBASE_STORAGE_BUCKET,
            messagingSenderId: FIREBASE_CONFIG.FIREBASE_SENDER_ID,
            appId: FIREBASE_CONFIG.FIREBASE_APP_ID,
            measurementId: FIREBASE_CONFIG.FIREBASE_MEASUREMENT_ID
        };

        // Initialize Firebase app
        app = initializeApp(firebaseConfig);

        // Initialize Firebase messaging after app is initialized
        messaging = getMessaging(app);
        isInitialized = true;
    } catch (error) {
        console.error('Error initializing Firebase:', error);
        isInitialized = false;
    }
};

initializeFirebase();

const getFirebaseMessaging = () => {
    if (isInitialized) {
        return messaging;
    }
    console.error('Firebase not initialized yet');
    return null;
};
export default getFirebaseMessaging;
