// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import useRequestForToken from 'firebase/requestPermission';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { API_URL } from 'config';

// ==============================|| APP ||============================== //

const App = () => {
    useRequestForToken();

    const { logo, companyName } = useSelector((state) => state.companyLogo);
    useEffect(() => {
        if (companyName) {
            document.title = companyName;
        }
        if (logo) {
            const favicon = document.getElementById('favicon');
            favicon.href = `${API_URL}/${logo}`;
        }
    }, [logo, companyName]);
    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
