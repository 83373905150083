// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import listingReducer from './reducer/listing';
import validationReducer from './reducer/validation';
import companyDetails from './slices/company-details';
import notifications from './slices/notifications';
import fcmToken from './slices/fcmToken';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    listing: listingReducer,
    validation: validationReducer,
    companyLogo: companyDetails,
    notifications,
    fcmToken
});

export default reducer;
