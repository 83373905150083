// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    logo: null,
    companyName: ''
};

const companyLogoSlice = createSlice({
    name: 'company-logo',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        updateCompanyLogoSuccess(state, action) {
            state.logo = action.payload?.image;
            state.companyName = action.payload?.companyName;
        }
    }
});

// Reducer
export default companyLogoSlice.reducer;

// ----------------------------------------------------------------------

export function getCompanyLogo() {
    return async () => {
        try {
            const response = await axios.get(`/api/company-logo`);
            dispatch(companyLogoSlice.actions.updateCompanyLogoSuccess(response.data.data));
        } catch (error) {
            dispatch(companyLogoSlice.actions.hasError(error));
        }
    };
}
