import React, { useEffect, useState } from 'react';

// material-ui
import { Avatar, Button, Grid, Stack, TextField, Typography } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import useAuth from 'hooks/useAuth';

// third-party
import * as yup from 'yup';
import useHandleForm from 'hooks/useHandleForm';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'config';
import { get } from 'utils/axiosRequests';
import { globalAction, dispatch } from 'store';
import PhoneInput from 'react-phone-input-2';

// ==============================|| USER PROFILE EDIT ||============================== //

const validationSchema = yup.object({
    first_name: yup.string().required('First name is required.'),
    last_name: yup.mixed().required('Last name is required.'),
    phone_number: yup.mixed().required('Phone number is required.')
});

function UpdateProfile({ pageBehaviour }) {
    const { user } = useAuth();
    const changeFormSubmitValue = (values) => {
        const formData = new FormData();
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('phone_number', values.phone_number);
        formData.append('image', values.image);

        return formData;
    };

    const formikInitialValues = {
        user_id: user?.user_id,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email_address: user?.email_address,
        phone_number: user?.phone_number,
        image: user?.user_avatar
    };
    const url = React.useMemo(() => `/users/${user?.id}`, []);

    const handleResponce = (res) => {
        // if (res.status === 'success') {
        //      dispatch(
        //         globalAction('SET_CURRENT_DATA', {
        //             currentData: ""
        //         })
        //     );
        // }
    };

    const [formik, isLoading, currentData] = useHandleForm(
        `/api${url}`,
        'Edit',
        formikInitialValues,
        validationSchema,
        changeFormSubmitValue,
        handleResponce
    );

    const [currentProfileImage, setCurrentProfileImage] = useState('');

    useEffect(async () => {
        setCurrentProfileImage(`${API_URL}/${currentData?.user_avatar}`);
    }, [currentData]);

    const setCurrentImage = (e) => {
        e.preventDefault();
        setCurrentProfileImage(URL.createObjectURL(e.target.files[0]));
    };

    // useEffect(() => {
    //     get(`/api${url}`).then((data) => {
    //         dispatch(
    //             globalAction('SET_CURRENT_DATA', {
    //                 currentData: data
    //             })
    //         );
    //     });
    // }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <SubCard
                title="Profile Details"
                secondary={<Typography variant="h5">User ID: {user?.user_id}</Typography>}
                contentSX={{ textAlign: 'center' }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item sm={6} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Avatar alt="User 1" src={currentProfileImage} sx={{ width: 90, height: 90, margin: '0 auto' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="center">
                                    Upload/Change Your Profile Image
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" component="label" style={{ color: '#ffffff !important' }}>
                                    Choose
                                    <input
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        id="image"
                                        name="image"
                                        onChange={(event) => {
                                            formik.setFieldValue('image', event.currentTarget.files[0]);
                                            setCurrentImage(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} md={8}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} lg={6} md={6}>
                                <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    label="First Name*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} lg={6} md={6}>
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} lg={6} md={6}>
                                <TextField
                                    fullWidth
                                    id="email_address"
                                    name="email_address"
                                    label="Email*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.email_address}
                                    disabled
                                    onChange={formik.handleChange}
                                    error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                                    helperText={formik.touched.email_address && formik.errors.email_address}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} lg={6} md={6} sx={{ position: 'relative' }}>
                                {/* <TextField
                                    fullWidth
                                    id="phone_number"
                                    name="phone_number"
                                    label="Phone*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.phone_number}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                                /> */}
                                <small
                                    style={{
                                        position: 'absolute',
                                        top: 13,
                                        left: 32,
                                        backgroundColor: '#ffffff',
                                        zIndex: '999',
                                        padding: '0 5px'
                                    }}
                                >
                                    Contact Number*
                                </small>
                                <PhoneInput
                                    country="us"
                                    fullWidth
                                    name="phone_number"
                                    onFocus={{ border: 'none' }}
                                    id="phone_number"
                                    inputStyle={{
                                        border: 'none',
                                        background: '#fafafa',
                                        maxHeight: '48px',
                                        width: '100%',
                                        boxShadow: 'none'
                                    }}
                                    containerStyle={{
                                        padding: '0 10px 0 10px',
                                        border: '1px solid #cfcccc',
                                        borderRadius: '10px',
                                        maxHeight: '50px',
                                        background: '#fafafa',
                                        boxShadow: 'none'
                                    }}
                                    value={`${formik.values.phone_number}`}
                                    onChange={(e) => formik.setFieldValue('phone_number', e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                                    <AnimateButton alignSelf="right">
                                        <Button variant="contained" type="submit" alignSelf="right">
                                            Update Details
                                        </Button>
                                    </AnimateButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SubCard>
        </form>
    );
}

export default UpdateProfile;
