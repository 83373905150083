// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, IconButton, Tooltip } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useEffect } from 'react';
import { getAllNotifcations } from 'store/slices/notifications';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { Stack } from '@mui/system';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { user } = useAuth();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    useEffect(() => {
        dispatch(getAllNotifcations());
    }, []);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="20px" />
                </Avatar>
            </Box>

            {/* header search */}
            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            <Stack alignItems="center" direction="row" gap={1}>
                {user?.hasAccess('DailyEntry-read') && (
                    <Button variant="contained" size="small" startIcon={<PostAddIcon />} LinkComponent={Link} to="/daily-entry">
                        Daily Entry
                    </Button>
                )}
                {user?.user_type === 'Admin' && (
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<EngineeringIcon />}
                        LinkComponent={Link}
                        to="https://ensitebuilder.com/dashboard/ticket"
                        target="_blank"
                    >
                        Technical Support
                    </Button>
                )}
            </Stack>
            <NotificationSection />

            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
