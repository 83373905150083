import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// project imports
import useAuth from 'hooks/useAuth';
import Error from 'views/pages/maintenance/Error';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const RoleGuard = ({ children, userPermission, permissionID }) => {
    const { user } = useAuth();
    // if (userPermission.includes(user?.user_type)) {
    //     return children;
    // }
    if (user?.user_type === 'Admin') {
        return children;
    }
    if (user?.user_type !== 'Admin' && user?.Role?.accessIDs.split(',').includes(permissionID)) {
        return children;
    }

    return <Error />;
};

RoleGuard.propTypes = {
    children: PropTypes.node
};

export default RoleGuard;
