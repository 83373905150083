// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    notifications: []
};

const slice = createSlice({
    name: 'notfications',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        getNotifcationSuccess(state, action) {
            state.notifications = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllNotifcations() {
    return async () => {
        try {
            const response = await axios.get('/api/notifications');
            dispatch(slice.actions.getNotifcationSuccess(response?.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
