export const BASE_PATH = '';
export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const DASHBOARD_PATH = '/dashboard';

// FIREBASE CREDENTIALS
export const FIREBASE_CONFIG = {
    FIREBASE_API_KEY: undefined,
    FIREBASE_AUTH_DOMAIN: undefined,
    FIREBASE_PROJECT_ID: undefined,
    FIREBASE_STORAGE_BUCKET: undefined,
    FIREBASE_SENDER_ID: undefined,
    FIREBASE_APP_ID: undefined,
    FIREBASE_MEASUREMENT_ID: undefined,
    FIREBASE_VAPID_KEY: undefined,
    mapAPIKey: undefined
};

// Function to fetch data
const token = window.localStorage.getItem('serviceToken');
export const fetchFirebaseCredentialsData = async () => {
    try {
        const response = await fetch(`${API_URL}/api/firebase-credentials`, { headers: { Authorization: `Bearer ${token}` } });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was an error fetching the data:', error);
        throw error; // You can choose to handle the error or rethrow
    }
};

fetchFirebaseCredentialsData()
    .then((data) => {
        const fireBaseData = data?.data;
        FIREBASE_CONFIG.FIREBASE_API_KEY = fireBaseData?.apiKey;
        FIREBASE_CONFIG.FIREBASE_AUTH_DOMAIN = fireBaseData?.authDomain;
        FIREBASE_CONFIG.FIREBASE_PROJECT_ID = fireBaseData?.projectId;
        FIREBASE_CONFIG.FIREBASE_STORAGE_BUCKET = fireBaseData?.storageBucket;
        FIREBASE_CONFIG.FIREBASE_SENDER_ID = fireBaseData?.messagingSenderId;
        FIREBASE_CONFIG.FIREBASE_APP_ID = fireBaseData?.appId;
        FIREBASE_CONFIG.FIREBASE_MEASUREMENT_ID = fireBaseData?.measurementId;
        FIREBASE_CONFIG.FIREBASE_VAPID_KEY = fireBaseData?.vapid;
        FIREBASE_CONFIG.mapAPIKey = fireBaseData?.googleMapApiKey;
    })
    .catch((error) => {
        console.error('Error fetching data in config:', error);
    });

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
