import * as React from 'react';
import { useState, useMemo } from 'react';
// material-ui
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    FormControlLabel,
    Switch,
    Grid,
    Tooltip,
    Fab,
    Menu,
    Stack,
    Chip,
    Typography,
    Popover,
    IconButton
} from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// assets
import SearchIcon from '@mui/icons-material/Search';

// project imports
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Loading from './Loading';
import { Link } from 'react-router-dom';
// ==============================|| CUSTOMER LIST ||============================== //

const TableHeading = React.memo(({ columns, changeOrder, orderBy, order, status, action, filterIcon }) => (
    <TableHead>
        <TableRow>
            <TableCell>
                <TableSortLabel disabled onClick={(e) => changeOrder('id')}>
                    #
                </TableSortLabel>
            </TableCell>
            {columns.map(({ label, id }) => (
                <TableCell key={id} sortDirection={orderBy === id ? orderBy : false}>
                    <TableSortLabel
                        disabled
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={(e) => changeOrder(id)}
                    >
                        {label}
                    </TableSortLabel>
                </TableCell>
            ))}
            {status && (
                <TableCell>
                    <TableSortLabel disabled>Status</TableSortLabel>
                </TableCell>
            )}
            {action && <TableCell align="center">Action</TableCell>}
        </TableRow>
    </TableHead>
));

const Filter = React.memo(({ filter, filterIcon, listing }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateFilter = (type, column, chipValue, newValue) => {
        const newFilter = [...filter];
        const filterIndex = newFilter.findIndex((ele) => ele.column === column);

        switch (type) {
            case 'checkbox': {
                const chipIndex = newFilter[filterIndex].chip.findIndex((ele) => ele.value === chipValue);
                newFilter[filterIndex].chip[chipIndex].defaultValue = newValue;

                listing.setStateFilter(newFilter);
                break;
            }
            default:
                listing.setStateFilter(newFilter);
                break;
        }

        setAnchorEl(null);
    };

    return (
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            {filterIcon && (
                <Tooltip title="Filter" onClick={handleClick}>
                    <Fab color="primary" size="small" sx={{ boxShadow: 'none', ml: 1, width: 32, height: 32, minHeight: 32 }}>
                        <FilterAltOutlinedIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            )}
            <Menu
                id="menu-user-details-card"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {/* Filter Start */}
                {filter &&
                    filter.map((fData) => (
                        <div>
                            <Typography variant="subtitle1" sx={{ p: 1, pb: 0 }}>
                                {fData.label}
                            </Typography>

                            {fData.type === 'checkbox' && (
                                <Stack direction="row" spacing={1} sx={{ p: 1 }}>
                                    {fData.chip.map((chipData) => (
                                        <Chip
                                            label={chipData.label}
                                            style={
                                                chipData.defaultValue
                                                    ? {
                                                          color: theme.palette.primary.dark,
                                                          backgroundColor: theme.palette.primary.light
                                                      }
                                                    : {
                                                          color: theme.palette.grey.dark,
                                                          backgroundColor: theme.palette.grey.light
                                                      }
                                            }
                                            onClick={() => updateFilter(fData.type, fData.column, chipData.value, !chipData.defaultValue)}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </div>
                    ))}
                {/* Filter End */}
            </Menu>
        </Grid>
    );
});

const Listing = ({
    id,
    TableComponent,
    listing,
    columns,
    filter,
    status = true,
    action = true,
    filterIcon = true,
    editAction = true,
    viewAction = true,
    deleteAction = true
}) => {
    const [openSelectedPoper, setOpenSelectedPoper] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenActionMenu = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpenSelectedPoper(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popOverId = open ? 'simple-popover' : undefined;

    const serialNo = React.useCallback((index) =>
        listing.orderBy === 'id' && listing.order === 'asc'
            ? listing.total - listing.recordPerPage * (listing.currentPage - 1) - index
            : index + listing.recordPerPage * (listing.currentPage - 1) + 1
    );

    const colSpan = useMemo(() => {
        if (!status && !action) return columns.length + 1;
        if (!status || !action) return columns.length + 2;
        return columns.length + 3;
    }, []);

    const customTableBody = (arr, Component) =>
        arr.length > 0 ? (
            arr.map((data, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell>{serialNo(index)}</TableCell>
                    {Component(data)}
                    {status && (
                        <TableCell>
                            <FormControlLabel
                                control={<Switch checked={data.status === 'Active'} onChange={(e) => listing.changeStatus(e, data.id)} />}
                            />
                        </TableCell>
                    )}
                    {action && (
                        <TableCell align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>
                                <IconButton
                                    aria-describedby={popOverId}
                                    onClick={(e) => handleOpenActionMenu(e, data?.id)}
                                    size="small"
                                    sx={{ color: '#616161' }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Popover
                                    id={popOverId}
                                    open={open && openSelectedPoper === data?.id}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <Stack
                                        direction="column"
                                        textAlign="left"
                                        justifyContent="center"
                                        sx={{ minWidth: 104, py: 2, width: '100%' }}
                                    >
                                        {viewAction && (
                                            // <IconButton
                                            //     size="large"
                                            //     color="primary"
                                            //     component={Link}
                                            //     sx={{ padding: 0, display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}
                                            //     to={`/${id}/${data.id}`}
                                            // >
                                            //     <VisibilityTwoToneIcon /> <span style={{ fontSize: 14 }}>View</span>
                                            // </IconButton>
                                            <Button
                                                color="primary"
                                                LinkComponent={Link}
                                                to={`/${id}/${data.id}`}
                                                sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}
                                            >
                                                <VisibilityTwoToneIcon /> <span style={{ fontSize: 14 }}>View</span>
                                            </Button>
                                        )}
                                        {editAction && (
                                            <Button
                                                color="primary"
                                                onClick={(e) => listing.setCurrentData(data)}
                                                LinkComponent={Link}
                                                sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}
                                                to={`/${id}/${data.id}/edit`}
                                            >
                                                <EditTwoToneIcon /> <span style={{ fontSize: 14 }}>Edit</span>
                                            </Button>
                                        )}

                                        {deleteAction && (
                                            <Button
                                                color="error"
                                                onClick={(e) => listing.deleteRow(e, data.id)}
                                                sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}
                                            >
                                                <DeleteTwoToneIcon /> <span style={{ fontSize: 14 }}>Delete</span>
                                            </Button>
                                        )}
                                    </Stack>
                                </Popover>
                            </div>
                        </TableCell>
                    )}
                </TableRow>
            ))
        ) : (
            <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                    No Record Found!
                </TableCell>
            </TableRow>
        );

    return (
        <>
            {/* <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => listing.setSearchStr(e.target.value)}
                            placeholder="Search here"
                            value={listing.searchStr}
                            size="small"
                        />
                    </Grid>
                    {listing.stateFilter.length > 0 ? (
                        <Filter listing={listing} filter={listing.stateFilter} filterIcon={filterIcon} />
                    ) : (
                        <Filter listing={listing} filter={filter} filterIcon={filterIcon} />
                    )}
                </Grid>
            </CardContent> */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeading
                        listing={listing}
                        columns={columns}
                        changeOrder={listing.changeOrder}
                        order={listing.order}
                        orderBy={listing.orderBy}
                        status={status}
                        action={action}
                        filterIcon={filterIcon}
                    />
                    <TableBody>
                        {listing.isLoading ? (
                            <Loading colSpan={colSpan} />
                        ) : (
                            customTableBody(listing.listingData, (data) => TableComponent(data))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={listing.total}
                rowsPerPage={listing.recordPerPage}
                page={listing.currentPage - 1}
                onPageChange={listing.setpage}
                onRowsPerPageChange={listing.rowsPerPage}
            />
        </>
    );
};

export default Listing;
