// useRequestForToken.js

import { getToken } from 'firebase/messaging';
import { FIREBASE_CONFIG } from 'config';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axios';
import { updateFcmToken } from 'store/slices/fcmToken';
import { useDispatch } from 'react-redux';
import getFirebaseMessaging from './firebaseConfig';

const useRequestForToken = () => {
    const [fcmToken, setFcmToken] = useState(null);
    const dispatch = useDispatch();

    const sendTokenToServer = async (token) => {
        if (!token) return;
        try {
            const response = await axiosServices.post('/api/fcm-token', { fcmToken: token });
        } catch (error) {
            console.error('Error sending token to server: ', error);
        }
    };

    useEffect(() => {
        const getAndSendToken = async () => {
            const messaging = getFirebaseMessaging(); // Get the messaging object from firebaseConfig
            if (!messaging) {
                console.log('Firebase messaging is not initialized yet');
                return;
            }

            try {
                const currentToken = await getToken(messaging, {
                    vapidKey: FIREBASE_CONFIG.FIREBASE_VAPID_KEY
                });
                if (currentToken) {
                    setFcmToken(currentToken);
                    sendTokenToServer(currentToken);
                    dispatch(updateFcmToken(currentToken));
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            } catch (err) {
                console.log('An error occurred while retrieving token. ', err);
                setFcmToken(null);
                dispatch(updateFcmToken(null));
            }
        };

        setTimeout(getAndSendToken, 3000);
    }, [dispatch]);

    return fcmToken;
};

export default useRequestForToken;
