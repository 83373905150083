import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import axiosServices from 'utils/axios';
import { getAllNotifcations } from 'store/slices/notifications';
import messaging from 'firebase/firebaseConfig';
import { onMessage } from 'firebase/messaging';
import { dispatch, globalAction } from 'store';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { user } = useAuth();
    const { fcmToken } = useSelector((state) => state.fcmToken);
    const { notifications } = useSelector((state) => state.notifications);
    const [messages, setMessages] = useState([]);

    const [open, setOpen] = useState(false);
    let filterNotifications = 0;
    filterNotifications = messages?.length > 0 && messages?.filter((item) => item?.readStatus === false);

    const anchorRef = useRef(null);

    useEffect(() => {
        setMessages(notifications);
    }, [notifications]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    const readAllNotifications = () => {
        axiosServices.put(`/api/notifications/read-notification/${user?.id}`).then((res) => {
            if (res?.data?.status === 'success') {
                handleToggle();
                dispatch(getAllNotifcations());
            }
        });
    };

    useEffect(() => {
        if (fcmToken && 'serviceWorker' in navigator) {
            const fcmMessaging = messaging;
            onMessage(fcmMessaging, (payload) => {
                const { notification, data } = payload;
                dispatch(globalAction('SHOW_TOASTER', { status: 'success', message: notification?.title }));
                const newMsg = {
                    title: notification?.title,
                    subTitle: notification?.body,
                    path: data?.path,
                    readStatus: false,
                    id: data?.id
                };
                setMessages((msg) => [newMsg, ...msg]);
            });
        }
    }, [fcmToken]);
    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge
                    badgeContent={filterNotifications?.length}
                    color="primary"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    sx={{ cursor: 'pointer' }}
                >
                    <IconBell stroke={1.5} size="26px" />
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">All Notification</Typography>
                                                            <Chip
                                                                size="small"
                                                                label={messages?.length}
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    <NotificationList
                                                        notifications={messages}
                                                        dispatch={dispatch}
                                                        handleToggle={handleToggle}
                                                    />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        {notifications?.length > 0 && (
                                            <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                                <Button size="small" disableElevation onClick={readAllNotifications}>
                                                    Mark as all read
                                                </Button>
                                            </CardActions>
                                        )}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
