// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    fcmToken: null
};

const slice = createSlice({
    name: 'fcmToken',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        updateFcmToken(state, action) {
            state.fcmToken = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { updateFcmToken } = slice.actions;
