import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, TextField } from '@mui/material';

// Project import
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// third-party
import * as yup from 'yup';
import { ref } from 'yup';
import useHandleForm from 'hooks/useHandleForm';

// Validation
const validationSchema = yup.object({
    oldPassword: yup.string().required('Current Password is required'),
    newPassword: yup.string().min(6, 'Enter min 6 character').required('New Password is required'),
    confirm_password: yup
        .string()
        .required('Confirm password is required')
        .oneOf([ref('newPassword')], 'Passwords do not match')
});

// ====================== || CHANGE PASSWORD || ==========================

function UpdateSecurity() {
    const theme = useTheme();
    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirm_password: ''
    };
    const [formik, isLoading] = useHandleForm('/api/users/change-password', 'Add', initialValues, validationSchema);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item sm={12} lg={8} md={10}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <SubCard title="Change Password">
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="oldPassword"
                                            name="oldPassword"
                                            label="Current Password*"
                                            type="password"
                                            autoComplete="off"
                                            value={formik.values.oldPassword}
                                            onChange={formik.handleChange}
                                            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            id="newPassword"
                                            name="newPassword"
                                            label="New Password*"
                                            type="password"
                                            autoComplete="off"
                                            value={formik.values.newPassword}
                                            onChange={formik.handleChange}
                                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            id="confirm_password"
                                            name="confirm_password"
                                            label="Re-enter New Password*"
                                            type="password"
                                            autoComplete="off"
                                            value={formik.values.confirm_password}
                                            onChange={formik.handleChange}
                                            error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                                            helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row">
                                            <AnimateButton>
                                                <Button variant="contained" type="submit">
                                                    Change Password
                                                </Button>
                                            </AnimateButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
}

export default UpdateSecurity;
