import * as React from 'react';
import { Link } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { TableCell, Typography, Button, Grid } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
// assets
import useListing from 'hooks/useListing';
import Listing from 'ui-component/Listing';
// ==============================|| CUSTOMER LIST ||============================== //

const TranningCertificateList = () => {
    const theme = useTheme();

    return (
        <MainCard title={<Typography variant="h3">Tranning Certificate</Typography>} content={false}>
            <Grid container sx={{ p: 3 }}>
                <Grid item>
                    <Typography variant="body">Tranning Certificate Detail</Typography>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default TranningCertificateList;
